<template>
    <v-snackbar
        v-model="model"
        multi-line
        :color="color"
        top>
        {{ text }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="model = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import hasModel from "@/mixins/hasModel";

export default {
    mixins: [hasModel],
    props: {
        color: {
            type: String,
            required: false,
            default: 'primary',
        },
        timeout: {
            type: Number,
            required: false,
            default: 2000
        },
        text: {
            type: String,
            required: false
        }
    },
}
</script>
