import axios from '@/plugins/axios'

export default {
    namespaced: true,
    state: {
        agendaTerms: []
    },
    mutations: {
        setAgendaTerms(state, items) {
            state.agendaTerms = items;
        }
    },
    actions: {
        agendaTerms({commit, state}) {
            return state.agendaTerms.length ?
                Promise.resolve(state.agendaTerms) :
                axios.get('/1.1/terms/agenda')
                    .then(response => {
                        commit('setAgendaTerms', response.data || []);
                        return Promise.resolve(response.data || [])
                    }).catch(err => Promise.reject(err));
        }
    }
}
