<template>
	<v-row justify="center" align="center">
		<div class="loader-box">
			<div class="loader"></div>
			<itw-icon :width="60"></itw-icon>
		</div>
	</v-row>
</template>

<script>
    import itwIcon from '@/components/icons/itweb'

    export default {
        components: {
            itwIcon
        }
    }
</script>

<style>

	.loader-box {
		height: 90px;
		width: 90px;
		position: relative;
	}

	.loader-box .loader {
		position: absolute;
		border: 8px solid #fff;
		border-radius: 50%;
		border-top: 8px solid #cc2c0e;
		border-left: 8px solid #cc2c0e;
		width: 90px;
		height: 90px;
		-webkit-animation: spin 1.8s ease-in-out infinite;
		animation: spin 1.8s ease-in-out infinite;
		background: black
	}

	.loader-box .itw-logo {
		position: absolute;
		transform: scale(0.5);
		top: 20px;
		left: -8px;
	}

	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}


</style>
