import moment from 'moment'

export const article = (publication) => {
    return {
        ad: false,
        agreement: null,
        blurb: '',
        boilerplate: '',
        categories: [],
        contacts: [],
        contributors: [],
        createdAt: {
            date: new Date().toISOString().substr(0, 10),
            time: new Date().toLocaleTimeString()
        },
        description: '',
        elements: [],
        issuePlace: 'Johannesburg',
        intellitext: true,
        keywords: [],
        kind: 'article',
        modifiedAt: new Date().toISOString(),
        product: null,
        publication: publication || 'itweb',
        publishAt: (moment().add(2, 'hours')).toISOString(),
        publishAtModified: false,
        related: [],
        state: 'draft',
        text: '',
        title: '',
        xheads: [],
    }
};

