import axios from '@/plugins/axios'

export default {
    namespaced: true,
    state: {
        items: []
    },
    mutations: {
        set(state, items) {
            state.items = items;
        }
    },
    actions: {
        get({commit, state}) {
            return state.items.length ?
                Promise.resolve(state.items) :
                axios.get('/1.1/terms/categories?orderby=term-asc&limit=all')
                    .then(response => {
                        commit('set', response.data || []);
                        return Promise.resolve(response.data || [])
                    })
                    .catch(err => Promise.reject(err));
        }
    }
}
