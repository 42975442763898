import axios from '@/plugins/axios'

export default {
    namespaced: true,
    state: {
        items: [],
        pagination: null,
        filters: {}
    },
    mutations: {
        setItems(state, items) {
            state.items = items;
        },
        addItem(state, items){
            state.items.push(items);
        },
        removeItem(state, items){
            let index = state.items.findIndex(agreement => agreement.id === items.id)
            state.items.splice(index, 1);
        },
        setPage(state, items){
            state.pagination = items
        },
        setFilters(state, items){
            state.filters = items
        }
    },
    actions: {
        get({commit, state}, filters) {
            return state.items.length ?
                Promise.resolve(state.items) :
                axios.get(`/v3/agreements?${filters}`)
                    .then(response => {
                        commit('setItems', response.data || []);
                        return Promise.resolve(response.data || [])
                    })
                    .catch(Promise.reject);
        }
    }
}
