<template>
    <svg viewBox="0 0 215 95" version="1.1" xmlns="http://www.w3.org/2000/svg" :style="`height:${height}px;`" class="itw-logo">
        <path fill="#ee3425"
              d=" M 17.75 18.27 C 14.52 11.42 20.36 2.50 28.02 3.22 C 36.95 2.77 42.04 14.96 35.85 21.25 C 35.75 21.36 35.54 21.59 35.44 21.70 C 30.42 27.07 20.32 25.22 17.75 18.27 Z"/>
        <path fill="#2e2e2e"
              d=" M 27.26 10.00 C 29.46 9.89 31.91 11.52 31.58 13.96 C 32.13 17.70 26.61 19.31 24.63 16.46 C 22.63 14.13 24.34 10.41 27.26 10.00 Z"/>
        <path fill="#eeeeee"
              d=" M 35.85 21.25 C 40.28 21.25 44.70 21.25 49.13 21.25 C 54.25 40.59 59.44 59.91 64.57 79.24 C 58.97 79.25 53.36 79.26 47.76 79.24 C 47.74 64.58 47.76 49.92 47.74 35.26 C 43.66 35.24 39.58 35.27 35.49 35.24 C 35.48 30.73 35.55 26.21 35.44 21.70 C 35.54 21.59 35.75 21.36 35.85 21.25 Z"/>
        <path fill="#ee3425"
              d=" M 49.13 21.25 C 54.80 21.25 60.48 21.25 66.15 21.26 C 67.25 25.92 68.35 30.57 69.49 35.22 C 70.85 41.08 72.33 46.91 73.67 52.77 C 73.83 52.80 74.15 52.87 74.32 52.91 C 76.75 42.34 79.44 31.83 81.83 21.26 C 86.57 21.25 91.31 21.24 96.05 21.26 C 99.01 32.23 101.51 43.32 104.64 54.25 C 106.85 43.17 109.96 32.28 112.50 21.27 C 117.13 21.23 121.75 21.26 126.38 21.25 C 121.27 40.59 116.20 59.95 110.96 79.25 C 105.91 79.25 100.86 79.26 95.81 79.24 C 93.20 68.89 90.66 58.50 87.88 48.19 C 85.36 58.54 82.88 68.90 80.39 79.25 C 75.12 79.24 69.85 79.26 64.57 79.24 C 59.44 59.91 54.25 40.59 49.13 21.25 Z"/>
        <path fill="#ffffff"
              d=" M 66.15 21.26 C 69.68 21.25 73.21 21.25 76.74 21.25 C 76.78 25.87 76.70 30.48 76.78 35.09 C 74.38 35.45 71.92 35.18 69.49 35.22 C 68.35 30.57 67.25 25.92 66.15 21.26 Z"/>
        <path fill="#ee3425"
              d=" M 167.01 21.25 C 172.67 21.25 178.33 21.25 183.99 21.25 C 184.01 27.73 184.00 34.20 183.99 40.68 C 188.96 32.78 201.95 33.46 207.04 40.86 C 211.01 46.33 211.81 53.44 211.46 60.01 C 210.94 66.44 208.84 73.42 203.26 77.24 C 195.82 82.09 184.58 81.13 179.03 73.81 C 177.96 75.49 177.42 77.41 176.79 79.28 C 173.53 79.23 170.27 79.27 167.01 79.24 C 166.99 59.91 167.00 40.58 167.01 21.25 Z"/>
        <path fill="#ffffff"
              d=" M 12.53 52.45 C 17.17 44.46 20.23 35.71 22.94 26.92 C 24.18 27.22 25.44 27.51 26.69 27.78 C 26.84 29.86 26.21 31.89 26.07 33.96 C 24.46 46.65 26.51 59.40 26.72 72.10 C 26.44 77.73 24.91 83.69 20.68 87.70 C 17.89 90.25 13.76 91.29 10.16 89.95 C 5.30 88.32 2.80 82.87 2.98 78.02 C 2.56 68.52 8.73 60.67 12.53 52.45 Z"/>
        <path fill="#ee3425"
              d=" M 132.16 37.03 C 141.06 33.52 152.38 34.56 159.37 41.51 C 164.35 46.63 165.88 54.07 165.49 61.00 C 155.86 61.01 146.22 60.98 136.59 61.01 C 136.65 63.23 136.81 65.78 138.56 67.38 C 142.22 70.30 148.99 68.88 149.52 63.65 C 154.88 63.82 160.22 64.46 165.58 64.52 C 164.32 70.57 159.93 75.75 154.22 78.08 C 147.35 80.87 139.51 80.77 132.51 78.48 C 124.43 75.71 118.72 67.52 118.73 59.01 C 118.24 49.92 123.41 40.30 132.16 37.03 Z"/>
        <path fill="#2e2e2e"
              d=" M 136.38 52.45 C 136.08 48.34 139.51 44.16 143.86 45.38 C 147.33 45.74 148.50 49.56 148.40 52.54 C 144.40 52.43 140.39 52.57 136.38 52.45 Z"/>
        <path fill="#2e2e2e"
              d=" M 188.18 46.06 C 192.06 45.64 193.96 49.73 194.06 53.00 C 194.28 57.62 194.81 62.58 192.87 66.93 C 191.45 70.38 185.48 70.31 184.67 66.47 C 183.46 61.74 184.19 56.81 184.00 51.99 C 183.67 49.28 185.50 46.63 188.18 46.06 Z"/>
    </svg>
</template>

<script>
    export default{
        props: {
            width: {default: 48, type: [Number, String], required: false},
            height: {default: 48, type: [Number, String], required: false}
        }
    }
</script>
