import axios from '@/plugins/axios'

export default {
    namespaced: true,
    state: {
        search: '',
        pagination: {
            sortBy: 'name',
            rowsPerPage: 10,
            page: 1
        },
        items: []
    },
    mutations: {
        setItems(state, items) {
            state.items = items;
        },
        setSearch(state, search) {
            state.search = search;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        }
    },
    actions: {
        get({commit, state}) {
            return state.items.length ?
                Promise.resolve(state.items) :
                axios.get('1.1/contributors/agreements?publisher')
                    .then(response => {
                        commit('setItems', response.data || []);
                        return Promise.resolve(response.data || [])
                    })
                    .catch(Promise.reject);
        }
    }
}
