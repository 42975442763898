import axios from 'axios'
import cfg from '../config'
import store from '../store'

let instance = axios.create({baseURL: cfg.URL});


instance.interceptors.request.use(function (config) {
    if (store.state.publication.type === 'itweb') config.headers.common['Accept'] = 'application/json, itweb/itweb.v1';
    else if (store.state.publication.type === 'africa') config.headers.common['Accept'] = 'application/json, itweb/africa.v1';
    else if (store.state.publication.type === 'mag') config.headers.common['Accept'] = 'application/json, itweb/mag.v1';
    else if (store.state.publication.type === 'brainstorm') config.headers.common['Accept'] = 'application/json, itweb/brainstorm.v1';
    else config.headers.common['Accept'] = 'application/json, itweb/itweb.v1';

    if (window.localStorage.getItem('itw_access_token')) {
        const token = window.localStorage.getItem('itw_access_token');
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
});
instance.interceptors.response.use(undefined, function (err) {
    let data = (err.response && err.response.data) || {};
    if (err.response && err.response.status === 401 && data.error === 'Unauthenticated.') {

        return axios.post(cfg.URL + 'auth/login',
            {
                grant_type: 'refresh_token',
                client_id: cfg.client_id,
                client_secret: cfg.client_secret
            }, {withCredentials: true})
            .then(function (response) {
                let accessToken = response.data.access_token;
                localStorage.setItem('itw_access_token', accessToken);
                err.config.headers.Authorization = 'Bearer ' + accessToken;
                return axios(err.config);
            })
            .catch(function () {
                console.log('interceptor:no-cookie')
                localStorage.removeItem('itw_access_token');
                return Promise.reject();
            });
    }
    return Promise.reject(err);
});
export default instance;
