import cfg from '@/config'
import axios from '@/plugins/axios'
import {firebase, firestore} from '@/firebase'

const guest = {
    id: null,
    name: null,
    email: null,
    state: 0,
    profiles: [],
    permissions: [],
    roles: []
};

export default {
    namespaced: true,
    state: {
        id: null,
        name: null,
        email: null,
        state: 0,
        profiles: [],
        permissions: [],
        roles: []
    },
    mutations: {
        set(state, user) {
            state.id = user.id;
            state.name = user.name;
            state.email = user.email;
            state.state = user.state;
            state.profiles = user.profiles;
            state.contributor = user.contributor;
            state.permissions = user.permissions || [];
            state.roles = user.roles || [];
        }
    },
    getters: {
        authenticated(state) {
            return !!state.id;
        },
        initials(state) {
            return state.name ?
                state.name
                    .split(' ')
                    .filter(word => word.trim().length)
                    .map(word => word.trim()[0])
                    .join('') :
                ''
        },
        roles(state) {
            return state.roles;
        },
        publications(state) {
            const unique = (value, index, self) => {
                return self.indexOf(value) === index
            }
            return state.roles
                .map(role => role.publication)
                .filter(unique)
        },
        permissions(state, getters, rootState) {
            return state.roles
                .filter(role => role.permissions && role.publication === rootState.publication.type)
                .map(role => role.permissions)
                .reduce((permissions, permission) => {
                    permissions = permissions.concat(permission)
                    return permissions;
                }, []);
        },
        can(state, getters) {
            return permissions => {
                let hasAbility = false;
                let allowedAbilities = Array.isArray(permissions) ? permissions : [permissions];
                getters.permissions.forEach(perm => {
                    if (allowedAbilities.find(ability => ability === perm.name)) {
                        hasAbility = true
                    }
                });
                return hasAbility;
            }
        },
        is(state, getters, rootState) {
            return roles => {
                let isRole = false;
                let allowedRoles = Array.isArray(roles) ? roles : [roles];
                state.roles.forEach(role => {
                    if (role.slug === 'administrator1' || (allowedRoles.find(r => r.toLowerCase() === role.name.toLowerCase()) && role.publication == rootState.publication.type)) {
                        isRole = true
                    }
                });
                return isRole
            }
        }
    },
    actions: {
        login({commit, dispatch}, credentials) {
            return axios.post('/auth/login', {
                'client_id': cfg.client_id,
                'client_secret': cfg.client_secret,
                'username': credentials.username,
                'password': credentials.password,
                'grant_type': 'password'
            }, {
                withCredentials: true
            }).then(response => {
                let accessToken = response.data.access_token;
                localStorage.setItem('itw_access_token', accessToken);
                return dispatch('get');
            }).catch((e) => {
                return Promise.reject(e)
            })
        },
        get({commit}) {
            return axios.get('/1.1/me')
                .then(response => {
                    const user = response.data;
                    const currentUser = firebase.auth().currentUser;
                    const success = fbUser => {
                        commit('set', user);
                        return fbUser
                    };
                    if (currentUser) {
                        return Promise
                            .resolve(currentUser)
                            .then(success)
                            .catch(success)
                    } else {
                        return firebase.auth()
                            .signInWithCustomToken(user.firebase.token)
                            .then(success)
                            .catch(success)
                    }
                }).catch((err) => {
                    firebase.auth().signOut();
                    return Promise.reject(err)
                });
        },
        logout({commit}) {
            return axios.post('/auth/logout', {}, {withCredentials: true})
                .then(() => {
                    localStorage.removeItem('itw_access_token');
                    return firebase.auth()
                        .signOut()
                        .then(() => commit('set', guest));
                }).catch(err => Promise.reject(err));
        }
    }
}
