<template>
    <v-app v-if="roles" :class="!allowInspectlet && 'inspectletIgnore'" class="itw-app">
        <v-navigation-drawer
            v-model="drawer"
            fixed
            :clipped="clipped"
            app>
            <v-toolbar class="primary">
            </v-toolbar>
            <v-list>
                <v-list-item
                    value="true"
                    v-for="(item, i) in allowed"
                    :key="i"
                    :to="{name: item.to}">
                    <v-list-item-action>
                        <v-icon v-html="item.icon"></v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar
            app
            fixed
            :clipped-left="clipped"
            class="secondary application--dark"
            dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="ml-0">
                <v-row align="end" no-gutters>
                    <router-link :to="{ name: 'index' }" style="display: inline-flex">
                        <itweb-africa-icon v-if="publication == 'africa'" size="48"></itweb-africa-icon>
                        <itweb-icon v-else height="48"></itweb-icon>
                    </router-link>
                    <small class="ml-2 white--text hidden-md-and-down"
                           style="font-family: 'Roboto Slab';font-size: 24px;">
                        n.b.
                    </small>
                </v-row>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-select
                :items="allowedPublications"
                v-model="publication"
                label="Publication"
                style="max-width: 200px;"
                hide-details
                class="mt-2 mr-5"
                :disabled="allowedPublications.length === 1"></v-select>

            <v-menu
                absolute
                right
                offset-x
                :close-on-content-click="false"
                :nudge-width="200"
                :nudge-right="400"
                v-model="menu">
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        fab
                        color="primary"
                        :small="isPhone">
                        {{ initials }}
                    </v-btn>
                </template>
                <v-card>
                    <v-list>
                        <v-list-item avatar>
                            <v-list-item-avatar>
                                <v-icon>people</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ user.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="menu = false">Cancel</v-btn>
                        <v-btn color="primary" text @click="logout()">Logout</v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </v-app-bar>

        <v-main>
            <v-row no-gutters class="white" v-if="$route.name !== 'events-id-registration-desk'">
                <v-breadcrumbs :items="allBreadcrumbsItems" divider=">">
                    <template v-slot:item="props">
                        <router-link
                            v-if="props.item.to && !props.item.disabled"
                            :to="props.item.to"
                            class="body-1"
                            :class="[props.item.disabled && 'disabled']">{{ props.item.text }}
                        </router-link>
                        <span class="grey--text body-1" v-else>{{ props.item.text }}</span>
                    </template>
                </v-breadcrumbs>
            </v-row>
            <v-divider></v-divider>
            <div class="px-4 py-2" style="background-color: #f5f6fa;min-height: calc(100vh - 128px)">
                <slot></slot>
            </div>
        </v-main>
    </v-app>
</template>

<script>
import itwebIcon from '../components/icons/itweb'
import itwebAfricaIcon from '../components/icons/itweb-africa'
import menu from '../util/menu'
import {mapState, mapGetters} from 'vuex'

export default {
    name: 'App',
    props: {
        allowInspectlet: {
            type: Boolean,
            required: false,
            default: false
        },
        breadcrumbs: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    components: {
        itwebIcon,
        itwebAfricaIcon
    },
    data: () => ({
        menu: false,
        clipped: false,
        drawer: false,
        copyright: "Copyright © 1996 - " + new Date().getFullYear() + " ITWeb Limited. All rights reserved."
    }),
    methods: {
        logout() {
            this.$store.dispatch('user/logout')
            .then(() => {
                localStorage.removeItem('itw_access_token');
                this.$router.replace({path: '/login'});
            })
        }
    },
    computed: {
        ...mapState({user: 'user'}),
        ...mapGetters({initials: 'user/initials'}),
        ...mapGetters({roles: 'user/roles'}),
        ...mapGetters({can: 'user/can'}),
        ...mapGetters({allowedPublications: 'user/publications'}),
        allowed() {
            return menu.links.filter(link => this.can(link.permissions) && (link.publication == this.publication || !link.publication))
        },
        allBreadcrumbsItems() {
            let home = {
                text: 'Home',
                disabled: this.$route.name === 'index',
                to: {name: 'index'}
            };
            return [home, ...this.breadcrumbs]
        },
        publication: {
            get() {
                return this.$store.state.publication.type
            },
            set(value) {
                this.$store.commit('publication/setPublication', value);
                window.location.reload();
            }
        }
    }
}
</script>
