<template>
    <v-app class="itw-app">
        <itw-snackbar
            v-model="snackbar.display"
            :color="snackbar.color"
            :text="snackbar.text">
        </itw-snackbar>
        <v-row class="full-width fill-height" align="center" justify="center" v-if="loading">
            <itw-loader/>
        </v-row>
        <template v-else>
            <itw-forbidden v-if="forbidden">Forbidden</itw-forbidden>
            <router-view v-else/>
        </template>
    </v-app>
</template>

<script>
import itwLoader from '@/components/global/Loader'
import itwForbidden from '@/components/403'
import menu from '@/util/menu'
import {mapGetters, mapState} from 'vuex'
import {firebase} from '@/firebase'
import ItwSnackbar from '@/components/global/snackbar'

export default {
    name: 'App',
    components: {
        itwLoader,
        itwForbidden,
        ItwSnackbar
    },
    data: () => ({
        loading: true,
        forbidden: false
    }),
    created() {
        this.initialize();
        firebase.auth().onAuthStateChanged((user) => {
            // console.log('%cfirebase.auth ' + (user ? user.uid : ''), 'color:orange');
            if (user) {
                // decode custom token claims
                user.getIdToken().then(idToken => {
                    const b64DecodeUnicode = function (str) {
                        return decodeURIComponent(atob(str).replace(/(.)/g, function (m, p) {
                            let code = p.charCodeAt(0).toString(16).toUpperCase();
                            if (code.length < 2) code = '0' + code;
                            return '%' + code;
                        }));
                    };
                    const payload = JSON.parse(b64DecodeUnicode(idToken.split('.')[1]));
                    // console.log('Firebase.auth:customClaims', payload)
                })
            }
        })
    },
    watch: {
        '$route'(to, from) {
            this.forbidden = false;
            if (to.name !== 'login' || from.name !== to.name) {
                this.guard()
            }
        }
    },
    methods: {
        guard() {
            if (this.authenticated) {
                if (!this.permissions.length && !this.contributor) {
                    this.forbidden = true
                } else {
                    console.log('else')
                    let to = menu.links.find(link => this.$route.name === link.to);
                    if (this.$route.name === 'login' || (to && !this.can(to.permissions))) {
                        let path = '/';
                        this.$router.push({path});
                    }
                }
            }
        },
        initialize() {
            this.$store.dispatch('user/get')
            .then(() => {
                // prev had access to publication but removed or a different user on the same machine
                if (this.allowedPublications.indexOf(this.publication) === -1) {
                    this.$store.commit('publication/setPublication', this.allowedPublications[0]);
                    if(this.allowedPublications.length) {
                        window.location.reload();
                    }
                }
            })
            .then(this.guard)
            .catch(error => {
                console.log('user/get/error', error);
                if (this.$route.name !== 'login') {
                    localStorage.setItem('redirect', this.$route.path);
                    this.$router.push({name: 'login'})
                }
            })
            .finally(() => this.loading = false)
        },
        logout() {
            this.$store.dispatch('user/logout')
            .then(() => {
                localStorage.removeItem('itw_access_token');
                this.$router.push({name: 'login'});
            })
        }
    },
    computed: {
        snackbar() {
            return this.$store.state.snackbar;
        },
        ...mapState({'contributor': state => state.user.contributor}),
        ...mapState({'publication': state => state.publication.type}),
        ...mapGetters({'roles': 'user/roles'}),
        ...mapGetters({'is': 'user/is'}),
        ...mapGetters({'can': 'user/can'}),
        ...mapGetters({'authenticated': 'user/authenticated'}),
        ...mapGetters({'permissions': 'user/permissions'}),
        ...mapGetters({allowedPublications: 'user/publications'})
    }
}
</script>
