import Vue from 'vue'

import App from './App'
import router from './router'
import axios from './plugins/axios'
import Layout from './layouts/default'
import store from './store'
import vuetify from './plugins/vuetify';
import isDevice from './mixins/isDevice';

Vue.mixin(isDevice);
import '@/assets/app.scss'

import contenteditableDirective from 'vue-contenteditable-directive'

Vue.use(contenteditableDirective);

import VueMoment from 'vue-moment'

Vue.use(VueMoment);

Vue.component('itw-page', Layout);

Vue.prototype.axios = axios;

Vue.config.productionTip = false;

Object.prototype.constructor.property = function (obj, path) {
    let output = obj;
    let properties = path.split('.');
    properties.forEach(property => {
        if (output) output = output[property]
    });
    return output
};
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.mixin({
    methods: {
        setSnackbar(promise, message = 'Success') {
            promise.then(() => {
                this.$store.commit('snackbar/setSnackbarColor', 'success');
                this.$store.commit('snackbar/setSnackbarText', message)
            })
            .catch(error => {
                this.$store.commit('snackbar/setSnackbarColor', '#b71c1c');
                this.$store.commit('snackbar/setSnackbarText', error.response.data.message || 'Whoops. Some error occurred!')
            })
            .finally(() => {
                this.$store.commit('snackbar/setSnackbarDisplay', true);
                this.model = false;
            })
        }
    },
});

Vue.filter('currency', function (value, symbol) {
    let val = parseFloat(value);
    return val || val === 0 ?
        symbol ? symbol + ' ' + val.toFixed(2) : val.toFixed(2) :
        value
});

Vue.filter('currency-format', function (value, symbol) {
    let val = parseFloat(value);
    return val || val === 0 ?
        symbol
            ? symbol + ' ' + val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
            : val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        : value
});

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    components: {App},
    template: '<App/>'
});
