import axios from '@/plugins/axios'

export default {
    namespaced: true,
    state: {
        items: []
    },
    mutations: {
        set(state, items) {
            state.items = items;
        }
    },
    actions: {
        people({commit, state}) {
            return state.items.length ?
                Promise.resolve(state.items) :
                axios.get('/1.1/contributors/people/popular')
                    .then(response => {
                        commit('set', response.data || []);
                        return Promise.resolve(response.data || [])
                    })
                    .catch((err) => {
                        return Promise.reject(err)
                    });
        },
        organization({commit, state}) {
            return state.items.length ?
                Promise.resolve(state.items) :
                axios.get('/1.1/contributors/organization/popular')
                    .then(response => {
                        commit('set', response.data || []);
                        return Promise.resolve(response.data || [])
                    })
                    .catch(err => Promise.reject(err));
        }
    }
}
