import axios from '@/plugins/axios'

export default {
    namespaced: true,
    state: {
        items: []
    },
    mutations: {
        set(state, items) {
            state.items = items;
        },
        reset(state) {
            state.items = [];
        }
    },
    actions: {
        get({commit, state}, endpoint) {
            return state.items.length ?
                Promise.resolve(state.items) :
                axios.get(endpoint)
                    .then(response => {
                        commit('set', response.data || []);
                        return Promise.resolve([response.data] || [])
                    })
                    .catch((err) => Promise.reject(err));
        },
    }
}
