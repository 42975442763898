import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/user'
import clients from '@/store/clients'
import categories from '@/store/categories'
import products from '@/store/products'
import contributors from '@/store/contributors'
import content from '@/store/content'
import agreements from '@/store/agreements'
import filetree from '@/store/filetree'
import events from '@/store/events'
import publication from '@/store/publication'
import snackbar from '@/store/snackbar'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user,
        clients,
        categories,
        contributors,
        products,
        content,
        agreements,
        events,
        filetree,
        publication,
        snackbar
    }
});

export default store;
