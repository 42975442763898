import VueFire from 'vuefire'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import Vue from 'vue'
import cfg from './config'

Vue.use(VueFire);
//
if (cfg.URL.includes('stageapi.itweb') || cfg.URL.includes('localhost')) {
    // https://console.cloud.google.com/firestore/data/drafts/GJQKFUjaAUY7qQjmVy7Q?project=notabene-23&authuser=1
    firebase.initializeApp({
        apiKey: "AIzaSyAgRZqnMYKnL1n4Eo3h9f8PYVkw4zuglu8",
        databaseURL: "https://notabene-23.firebaseio.com",
        projectId: "notabene-23"
    });
} else {
    firebase.initializeApp({
        apiKey: "AIzaSyAg15XDMc9yGSlE1RfDTP1D16n5UOqmY08",
        databaseURL: "https://itweb-1274.firebaseio.com",
        projectId: "itweb-1274"
    })
}

export {firebase}
export const firestore = firebase.firestore();
const settings = {};
firestore.settings(settings);
