export default{
    computed: {
        isPhone() {
            return this.$vuetify.breakpoint.smAndDown
        },
        isPhoneOrTablet() {
            return this.$vuetify.breakpoint.mdAndDown
        },
        isDesktop() {
            return this.$vuetify.breakpoint.mdAndUp
        }
    }
};