<template>
    <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="-1165 198.6 1143 340"
            style="enable-background:new -1165 198.6 1143 340;"
            :style="`height:${size}px`"
            xml:space="preserve">
        <path class="st0" d="M-1109.6,538.7c-31-6.6-41.3-48-19.9-91.2c32.6-66.3,43.6-75.6,61.7-140.7l14.2,3c-10,66.9-3.7,79.9-0.8,153.7
	C-1052.4,511.6-1078.6,545.3-1109.6,538.7"/>
        <circle class="st0" cx="-1049.9" cy="258.5" r="39.4"/>
        <path class="st0" d="M-536.9,498.3V285.4h61.4v73.2c5.3-8,10.8-13.7,17.5-17.1c6.7-3.4,14.8-5.1,24.1-5c14.7,0.3,26.4,4.4,35.2,12.4
	c8.7,8,15,18.1,18.8,30.3c3.8,12.2,5.7,24.8,5.6,37.7c0.1,14.9-2.1,28.7-6.7,41.5c-4.5,12.7-11.8,23-21.8,30.9
	c-10,7.8-23.3,11.9-39.8,12.1c-21.5,0.4-38.4-7.9-50.9-24.8c-3.6,6.9-6.4,14.2-8.4,21.7H-536.9z"/>
        <path class="st0" d="M-475.1,400v35.4c-0.3,6.6,0.7,12.7,2.8,18.5c2.1,5.7,7.1,8.8,14.9,9.2c6.9-0.3,11.8-2.9,14.7-7.9
	c2.9-5,4.6-10.8,5.2-17.5c0.6-6.7,0.8-12.7,0.6-18c0.1-5.3,0-11.3-0.6-18c-0.5-6.7-2.3-12.5-5.3-17.5c-3-5-8.1-7.6-15.3-7.9
	c-5.5,0.2-10,2.6-13.3,7.1c-2.1,2.6-3.2,5.2-3.5,7.9C-475.2,393.8-475.2,396.7-475.1,400z"/>
        <path class="st0" d="M-650,400.2h44.4c0.2-7.9-1.4-14.4-4.7-19.5c-3.3-5.1-9.2-7.7-17.7-7.8c-8,0.2-13.8,3-17.3,8.2
	C-648.8,386.3-650.4,392.7-650,400.2z"/>
        <path class="st0" d="M-601.6,441.5l58,3.7c-3.3,12.8-9,23.4-17,31.7c-8,8.3-17.6,14.5-28.7,18.5c-11.1,4-23,6-35.6,6
	c-11.7,0.1-23-1.4-33.9-4.5c-10.9-3.1-20.9-8.5-30-16.3c-8.6-7.7-15-16.7-19.4-27c-4.4-10.3-6.6-21.1-6.6-32.6
	c0-10.9,2.1-21.7,6.1-32.3c4-10.6,9.7-19.8,17.2-27.6c8.3-8.4,18-14.5,29.1-18.5c11.1-4,22.4-6,33.9-6c26.6,0.3,47.4,8.2,62.3,23.6
	c14.9,15.4,22.5,36.3,22.7,62.7v8.4h-106.1c-0.6,8.7,1,15.7,4.6,21.1c3.7,5.4,10.1,8.2,19.3,8.3C-612,461.2-603.9,454.8-601.6,441.5
	z"/>
        <polygon class="st0" points="-687.5,285.4 -744,498.3 -799,498.3 -828.4,381 -856.7,498.3 -913.8,498.3 -975.9,498.3 -975.9,336.6
	-1020.6,336.6 -1020.6,285.4 -970.6,285.4 -909.1,285.4 -870,285.4 -870,336.6 -896.9,336.6 -879.7,408.3 -850.2,285.4 -799,285.4
	-767.6,408.3 -737.5,285.4 "/>
        <path class="st1" d="M-1109.6,538.7c-31-6.6-41.3-48-19.9-91.2c32.6-66.3,43.6-75.6,61.7-140.7l14.2,3c-10,66.9-3.7,79.9-0.8,153.7
	C-1052.4,511.6-1078.6,545.3-1109.6,538.7"/>
        <circle class="st1" cx="-1049.9" cy="258.5" r="39.4"/>
        <path class="st1" d="M-536.9,498.3V285.4h61.4v73.2c5.3-8,10.8-13.7,17.5-17.1c6.7-3.4,14.8-5.1,24.1-5c14.7,0.3,26.4,4.4,35.2,12.4
	c8.7,8,15,18.1,18.8,30.3c3.8,12.2,5.7,24.8,5.6,37.7c0.1,14.9-2.1,28.7-6.7,41.5c-4.5,12.7-11.8,23-21.8,30.9
	c-10,7.8-23.3,11.9-39.8,12.1c-21.5,0.4-38.4-7.9-50.9-24.8c-3.6,6.9-6.4,14.2-8.4,21.7H-536.9z"/>
        <path class="st1" d="M-475.1,400v35.4c-0.3,6.6,0.7,12.7,2.8,18.5c2.1,5.7,7.1,8.8,14.9,9.2c6.9-0.3,11.8-2.9,14.7-7.9
	c2.9-5,4.6-10.8,5.2-17.5c0.6-6.7,0.8-12.7,0.6-18c0.1-5.3,0-11.3-0.6-18c-0.5-6.7-2.3-12.5-5.3-17.5c-3-5-8.1-7.6-15.3-7.9
	c-5.5,0.2-10,2.6-13.3,7.1c-2.1,2.6-3.2,5.2-3.5,7.9C-475.2,393.8-475.2,396.7-475.1,400z"/>
        <path class="st1" d="M-650,400.2h44.4c0.2-7.9-1.4-14.4-4.7-19.5c-3.3-5.1-9.2-7.7-17.7-7.8c-8,0.2-13.8,3-17.3,8.2
	C-648.8,386.3-650.4,392.7-650,400.2z"/>
        <path class="st1" d="M-601.6,441.5l58,3.7c-3.3,12.8-9,23.4-17,31.7c-8,8.3-17.6,14.5-28.7,18.5c-11.1,4-23,6-35.6,6
	c-11.7,0.1-23-1.4-33.9-4.5c-10.9-3.1-20.9-8.5-30-16.3c-8.6-7.7-15-16.7-19.4-27c-4.4-10.3-6.6-21.1-6.6-32.6
	c0-10.9,2.1-21.7,6.1-32.3c4-10.6,9.7-19.8,17.2-27.6c8.3-8.4,18-14.5,29.1-18.5c11.1-4,22.4-6,33.9-6c26.6,0.3,47.4,8.2,62.3,23.6
	c14.9,15.4,22.5,36.3,22.7,62.7v8.4h-106.1c-0.6,8.7,1,15.7,4.6,21.1c3.7,5.4,10.1,8.2,19.3,8.3C-612,461.2-603.9,454.8-601.6,441.5
	z"/>
        <polygon class="st1" points="-687.5,285.4 -744,498.3 -799,498.3 -828.4,381 -856.7,498.3 -913.8,498.3 -975.9,498.3 -975.9,336.6
	-1020.6,336.6 -1020.6,285.4 -970.6,285.4 -909.1,285.4 -870,285.4 -870,336.6 -896.9,336.6 -879.7,408.3 -850.2,285.4 -799,285.4
	-767.6,408.3 -737.5,285.4 "/>
        <g>
	<g>
		<path class="st2" d="M-251.8,207.6h13.8c-24.2,26.8-50.8,51.2-75.9,77.2c-6.6,6.4-12.9,15.3-23.3,14.1c3.3-13,14.6-20.9,23.4-30
			C-293,248.6-273.2,227.3-251.8,207.6z"/>
        <path class="st2" d="M-293.3,215.5c2.7-3.2,6.8-3.9,10.6-5.1c0.2,2.4,0.7,7.1,0.9,9.5c-13.7,13.2-27,26.9-40.5,40.4
			c-3.1,3.6-7.8,4.7-12,6.3c-1.9-5.4-0.5-10.6,4-14.1C-317.9,240.2-305.7,227.8-293.3,215.5z"/>
        <path class="st2" d="M-224.8,213c5.7-7,14.1,2.3,7.5,7.7c-35.1,35.7-70.8,70.8-106.1,106.4c-3,4.8-8.4,3.3-13.1,2.9
			c2-9.6,10-15.6,16.5-22.3C-288,276.5-256.8,244.2-224.8,213z"/>
        <path class="st2" d="M-174,228.4c2.1-0.5,6.3-1.6,8.4-2.1c4,7.1-2.9,11.8-7.2,16.4c-37.2,36.9-74,74.1-111.1,111
			c-3.3,6.1-15.9,4.4-11.2-4C-255.1,309-214.6,268.7-174,228.4z"/>
        <path class="st2" d="M-216.5,237.7c3.7-3.6,7.5-7.3,12.1-9.6c8.8,4.1,1.6,11.4-2.7,15.9c-31.2,30.9-62.3,61.9-93.2,93.2
			c-4.9,5.4-11.4,11.3-18.7,5.7c6.6-10.8,16.5-18.9,25.3-27.9C-267.8,289.2-242,263.5-216.5,237.7z"/>
        <path class="st2" d="M-145.8,233.2c5.9-6,13.5,2.3,6.9,7.9c-35.8,37-72.9,72.9-109,109.7c-3.8,4.7-9.4,7.1-15.4,5.3
			c1.3-5.9,4.4-11,8.9-14.9C-217.9,305.5-182.4,268.8-145.8,233.2z"/>
        <path class="st2" d="M-232.9,352.9c35.1-34.6,68.9-70.5,105.1-103.9c1.5,1.8,4.5,5.3,6,7c-27.1,30.4-57.3,57.8-85.6,87
			c-8.7,7.8-15.6,18.3-26.3,23.4C-241,364.6-237.6,355.9-232.9,352.9z"/>
        <path class="st2" d="M-134.4,287.6c6.5-6.2,12.2-14.9,22-15.8c0.2,2.3,0.5,6.7,0.6,8.9c-31.8,31.6-63.5,63.3-95.1,95.1
			c-7.2,6.8-13.4,15.8-23.6,18.2c-3.9-10.5,6.4-16.2,12.4-22.9C-190,343.4-162.1,315.5-134.4,287.6z"/>
        <path class="st2" d="M-211.1,397.1c35.7-34.4,69.5-71.1,106-104.6c1.9,0.3,5.6,0.8,7.4,1.1c-1.4,5-3.3,10-7.5,13.5
			c-33.3,33.1-66.5,66.4-99.7,99.7c-3.5,4.3-8.5,6-14,4.7C-217.9,405.9-215.6,400.6-211.1,397.1z"/>
        <path :class="dark ? 'fill-black' : 'fill-white'" d="M-79.9,305.9c16.4-4.3,32.9-8,48.9-13.5v3c-4.3,15.6-8.4,31.4-12.9,47c-5-5.2-10.2-10.3-15.5-15.2
			c-49.9,48.7-97.9,99.5-148.7,147.3c-1.4-2.3-4.1-6.8-5.5-9c48.8-48.7,97.7-97.2,146.3-146C-71.5,315-75.7,310.5-79.9,305.9z"/>
        <path class="st2" d="M-94.3,314.1c6.7-5.6,11.6,5.2,5.4,9.5c-38.9,39.3-78.4,78-117.2,117.4c-3.2,3-6.1,6.7-10.7,7.4
			c-7.4-2.4-3-10.4,1.1-13.8C-175,394.6-135.5,353.4-94.3,314.1z"/>
        <path :class="dark ? 'fill-black' : 'fill-white'" d="M-103.4,389.2c5.7-5.5,12.9,5.1,5.8,8.9c-32.8,33.6-66.3,66.7-99.7,99.8c-6,6.4-13.3-4.5-6.3-8.8
			C-170.3,455.7-137.2,422-103.4,389.2z"/>
        <path :class="dark ? 'fill-black' : 'fill-white'" d="M-108.2,426.2c3.3-4.5,8.6-5.5,13.7-3.8c-1,4.4-2.3,8.9-5.9,11.8c-28.1,27.7-55.7,56-83.9,83.5
			c-4.9,8.4-15-3.1-7.4-7.8C-164.1,481.8-135.9,454.3-108.2,426.2z"/>
        <path :class="dark ? 'fill-black' : 'fill-white'" d="M-154.5,505.4c8-7.5,15-16.3,24-22.6c4.5,0.3,8,5.6,4.6,9.3c-10.6,12.2-22.9,22.7-33.9,34.6
			c-2.6-0.1-7.8-0.4-10.4-0.6C-168.4,517-160.4,511.7-154.5,505.4z"/>
	</g>
            <path class="st4" d="M-1065.9,246.5"/>
            <path class="st4" d="M-1065.9,207.1c-21.8,0-39.4,17.6-39.4,39.4c0,21.8,17.6,39.4,39.4,39.4c21.8,0,39.4-17.6,39.4-39.4
		C-1026.5,224.7-1044.2,207.1-1065.9,207.1z M-1065.9,260.4c-7.7,0-13.9-6.2-13.9-13.9c0-7.7,6.2-13.9,13.9-13.9s13.9,6.2,13.9,13.9
		C-1052,254.1-1058.2,260.4-1065.9,260.4z"/>
            <polygon :class="dark ? 'fill-black' : 'fill-white'" points="-929.8,324.6 -929.8,486.3 -991.9,486.3 -991.9,324.6 -1036.6,324.6 -1036.6,273.4 -886,273.4
		-886,324.6 	"/>
            <path :class="dark ? 'fill-black' : 'fill-white'" d="M-1125.6,526.7c31,6.6,57.2-27.1,55.2-75.3c-2.9-73.8-9.1-86.8,0.8-153.7l-14.2-3
		c-18.1,65.1-29.1,74.5-61.7,140.7C-1166.9,478.7-1156.6,520.1-1125.6,526.7"/>
            <g>
		<path class="st7" d="M-552.9,486.3V273.4h61.4v73.2c5.3-8,10.8-13.7,17.5-17.1c6.7-3.4,14.8-5.1,24.1-5
			c14.7,0.3,26.4,4.4,35.2,12.4c8.7,8,15,18.1,18.8,30.3c3.8,12.2,5.7,24.8,5.6,37.7c0.1,14.9-2.1,28.7-6.7,41.5
			c-4.5,12.7-11.8,23-21.8,30.9c-10,7.8-23.3,11.9-39.8,12.1c-21.5,0.4-38.4-7.9-50.9-24.8c-3.6,6.9-6.4,14.2-8.4,21.7H-552.9z
			 M-491.2,388.6V424c-0.1,6,0.8,12.1,2.9,17.8c2.1,5.7,7.1,8.8,14.9,9.2c6.9-0.3,11.8-2.9,14.7-7.9c2.9-5,4.6-10.8,5.2-17.5
			c0.6-6.7,0.8-12.7,0.6-18c0.1-5.3,0-11.3-0.6-18c-0.5-6.7-2.3-12.5-5.3-17.5c-3-5-8.1-7.6-15.3-7.9c-5.5,0.2-10,2.6-13.3,7.1
			c-2.1,2.6-3.2,5.2-3.5,7.9C-491.2,381.8-491.2,384.7-491.2,388.6z M-666,388.2h44.4c0.2-7.9-1.4-14.4-4.7-19.5
			c-3.3-5.1-9.2-7.7-17.7-7.8c-8,0.2-13.8,3-17.3,8.2C-664.8,374.3-666.4,380.7-666,388.2z M-617.6,429.5l58,3.7
			c-3.3,12.8-9,23.4-17,31.7c-8,8.3-17.6,14.5-28.7,18.5c-11.1,4-23,6-35.6,6c-11.7,0.1-23-1.4-33.9-4.5c-10.9-3.1-20.9-8.5-30-16.3
			c-8.6-7.7-15-16.7-19.4-27c-4.4-10.3-6.6-21.1-6.6-32.6c0-10.9,2.1-21.7,6.1-32.3c4-10.6,9.7-19.8,17.2-27.6
			c8.3-8.4,18-14.5,29.1-18.5c11.1-4,22.4-6,33.9-6c26.6,0.3,47.4,8.2,62.3,23.6c14.9,15.4,22.5,36.3,22.7,62.7v8.4h-106.1
			c-0.6,8.7,1,15.7,4.6,21.1c3.7,5.4,10.1,8.2,19.3,8.3C-628,449.2-619.9,442.8-617.6,429.5z M-703.5,273.4L-760,486.3H-815
			L-844.4,369l-28.2,117.3h-57.1l-56.8-212.9h61.5l29.5,122.9l29.5-122.9h51.2l31.3,122.9l30.1-122.9H-703.5z"/>
	</g>
</g>
</svg>
</template>

<script>
    export default {
        props: {
            size: {
                type: [Number, String],
                required: false,
                default: '32'
            },
            dark: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    }
</script>

<style type="text/css">
    .st0 {
        display: none;
        fill: #FFFFFF;
    }

    .st1 {
        display: none;
        fill: none;
        stroke: #FFFFFF;
        stroke-width: 38.4685;
        stroke-miterlimit: 3.8637;
    }

    .st2 {
        fill: #EE3425;
    }

    .fill-white {
        fill: #FFFFFF;
    }
    .fill-black{
        fill: #000000;
    }

    .st4 {
        fill: #ED3024;
    }

    .st7 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #ED3024;
    }
</style>
