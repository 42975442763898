import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
        themes: {
            light: {
                primary: '#cc2c0e',
                accent: '#0c74bc',
                secondary: '#000'
            },
        },
    }
});
