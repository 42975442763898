export default {
    namespaced: true,
    state: {
        display: false,
        color: 'success',
        text: ''
    },
    mutations: {
        setSnackbarDisplay(state, value) {
            state.display = value;
        },
        setSnackbarColor(state, value) {
            state.color = value;
        },
        setSnackbarText(state, value) {
            state.text = value;
        }
    }
}
