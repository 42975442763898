import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const route = (route, prefix = null, dynamic = false) => {
    const name = prefix ?
        route === 'index' ? prefix : `${prefix}-${route}` : route;
    const path = route === 'index' ? '/' : `${dynamic ? ':' + route : route}`;
    const component = prefix ? () => import(`@/pages/${prefix.split('-').join('/')}/${route}`) : () => import(`@/pages/${route}`);
    if (route === 'evaluation') {
        console.log(`@/pages/${prefix.split('-').join('/')}/${route}`)
    }
    return {name, path, component};
}

export default new Router({
    mode: 'history',
    routes: [
        route('events-portals'),
        {
            path: '/forms',
            component: () => import('../layouts/layout'),
            children: [
                route('index', 'forms'),
                route('questions', 'forms'),
                {
                    path: 'copy',
                    name: 'forms-copy',
                    component: () => import('@/pages/forms/copy')
                },
                {
                    path: 'create',
                    name: 'forms-create',
                    component: () => import('@/pages/forms/create')
                },
                {
                    path: 'new',
                    name: 'forms-new',
                    component: () => import('@/pages/forms/id')
                },
                {
                    path: ':id',
                    component: () => import('../layouts/layout'),
                    children: [
                        {
                            path: 'users',
                            component: () => import('../layouts/layout'),
                            children: [
                                route('index', 'forms-id-users'),
                            ]
                        }
                    ]
                }
            ]
        },
        // {
        //     path: '/forms',
        //     component: () => import('../layouts/layout'),
        //     children: [
        //         route('index', 'forms'),
        //
        //     ]
        // },
        {
            path: '/agreements',
            component: () => import('../layouts/layout'),
            children: [
                route('index', 'agreements'),
                route('id', 'agreements', true),
                {
                    path: 'new',
                    name: 'agreements-new',
                    component: () => import('@/pages/agreements/id')
                }
            ]
        },
        {
            path: '/agreements/contributor/:id/tree',
            component: () => import('@/pages/agreements/contributor/tree'),
            name: 'agreements-contributor-id-tree'
        },
        {
            path: '/analytics',
            component: () => import('../layouts/layout'),
            children: [
                route('daily', 'analytics'),
                route('articles', 'analytics'),
                route('intellitext', 'analytics'),
                route('downloads', 'analytics'),
                {
                    path: 'agreements',
                    component: () => import('../layouts/layout'),
                    children: [
                        {
                            path: 'office',
                            name: 'analytics-agreements-office',
                            component: () => import('../layouts/layout'),
                            children: [
                                route('id', 'analytics-agreements-office', true)
                            ]
                        },
                        {
                            path: 'section',
                            name: 'analytics-agreements-section',
                            component: () => import('../layouts/layout'),
                            children: [
                                route('id', 'analytics-agreements-section', true)
                            ]
                        },
                        {
                            path: 'intellitext',
                            name: 'analytics-agreements-intellitext',
                            component: () => import('../layouts/layout'),
                            children: [
                                route('id', 'analytics-agreements-intellitext', true)
                            ]
                        },
                    ]
                }
            ]
        },
        {
            path: '/articles',
            component: () => import('../layouts/layout'),
            children: [
                route('index', 'articles'),
                {
                    path: 'drafts',
                    component: () => import('../layouts/layout'),
                    children: [
                        route('index', 'articles-drafts'),
                        route('id', 'articles-drafts', true),
                    ]
                }
            ]
        },
        {
            path: '/articles/images/get-real-url',
            name: 'articles-images-get-real-url',
            component: () => import('@/pages/images/getRealUrl')
        },
        {
            path: '/images/upload',
            name: 'images-upload',
            component: () => import('@/pages/images/upload')
        },
        route('categories'),
        route('books'),
        route('clients'),
        route('comments'),
        route('contributors'),
        {
            path: '/events/sync',
            name: 'events-sync',
            component: () => import('@/pages/events/sync')
        },
        {
            path: '/events',
            component: () => import('../layouts/layout'),
            children: [
                route('index', 'events'),
                //route('sync', 'events'),
                {
                    path: ':id',
                    component: () => import('../layouts/layout'),
                    children: [
                        {
                            path: 'agenda',
                            component: () => import('../layouts/layout'),
                            children: [
                                route('index', 'events-id-agenda'),
                                {
                                    path: 'timeslots',
                                    component: () => import('../layouts/layout'),
                                    children: [
                                        route('index', 'events-id-agenda-timeslots'),
                                        {
                                            path: ':aid',
                                            name: 'events-id-agenda-timeslots-aid',
                                            component: () => import('@/pages/events/id/agenda/timeslots'),
                                        },
                                    ]
                                }
                            ]
                        },
                        route('news', 'events-id'),
                        route('evaluation', 'events-id'),
                        route('gallery', 'events-id'),
                        route('report', 'events-id'),
                        route('modules', 'events-id'),
                        route('matchmaking', 'events-id'),
                        route('advisory-board', 'events-id'),
                        route('transactions', 'events-id'),
                        route('reminder', 'events-id'),
                        route('registration-desk', 'events-id'),
                        {
                            path: 'transactions',
                            component: () => import('../layouts/layout'),
                            children: [
                                {
                                    path: ':tid',
                                    component: () => import('../layouts/layout'),
                                    children: [
                                        route('lines', 'events-id-transactions-tid', true)
                                    ]
                                },
                            ]
                        },
                    ]
                }
            ]
        },
        route('frontpage'),
        route('ict-insight'),
        route('index'),
        //route('inspire'),
        route('tables'),
        route('qrcode'),
        route('search'),

        // route('layouts'),
        {
            path: '/layouts',
            component: () => import('../layouts/layout'),
            children: [
                route('index', 'layouts')
            ]
        },
        {
            path: '/layouts/:slug',
            name: 'layouts-slug',
            component: () => import('@/pages/layouts/index'),
        },
        {
            path: '/domainstats',
            name: 'domainstats',
            component: () => import('@/pages/domainstats/index'),
        },
        {
            path: '/layouts/:slug/mailchimp-stats',
            name: 'layouts-slug-stats',
            component: () => import('@/pages/layouts/stats'),
            // component: () => import('../layouts/layout'),
        },
        route('login'),
        route('menu'),
        {
            path: '/modules',
            component: () => import('../layouts/layout'),
            children: [
                route('index', 'modules')
            ]
        },
        route('office'),
        route('registrations'),
        route('spotlight'),
        route('cartoons'),
        {
            path: '/surveys',
            component: () => import('../layouts/layout'),
            children: [
                route('index', 'surveys'),
                {
                    path: ':id',
                    component: () => import('../layouts/layout'),
                    children: [
                        route('tabs', 'surveys-id'),
                        route('graph', 'surveys-id'),
                        route('validate', 'surveys-id'),
                        {
                            path: 'forms',
                            component: () => import('../layouts/layout'),
                            children: [
                                {
                                    path: ':form',
                                    component: () => import('../layouts/layout'),
                                    children: [
                                        route('validate', 'surveys-id-forms-form'),
                                    ]
                                }

                            ]
                        },
                    ]
                },
                {
                    path: 'graph',
                    name: 'surveys-graph',
                    component: () => import('../layouts/layout'),
                    children: [
                        route('id', 'surveys-graph', true),
                    ]
                },
            ]
        },
        {
            path: '/videos',
            component: () => import('../layouts/layout'),
            children: [
                route('index', 'videos'),
                route('id', 'videos', true),
                // {
                //     path: ':id',
                //     name:'video-id',
                //     component: () => import('../layouts/layout'),
                //     children:[
                //         route('index', 'videos-id', true)
                //     ]
                // }
            ]
        },
        {
            path: '/surveys2',
            component: () => import('../layouts/layout'),
            children: [
                route('index', 'surveys2'),
                {
                    path: 'graph',
                    name: 'surveys2-graph',
                    component: () => import('../layouts/layout'),
                    children: [
                        route('id', 'surveys2-graph', true),
                    ]
                }
            ]
        },
        {
            path: '/videos',
            component: () => import('../layouts/layout'),
            children: [
                route('index', 'videos'),
                route('id', 'videos', true),

            ]
        },
        {
            path: '/roomControl',
            component: () => import('../layouts/layout'),
            children: [
                route('index', 'roomControl'),
                route('test', 'roomControl'),
                route('id', 'roomControl', true),
            ]
        },
        {
            path: '/user',
            component: () => import('../layouts/layout'),
            children: [
                route('management', 'user'),
                route('roles', 'user'),
                route('permissions', 'user'),
            ]
        },
        {
            path: '/forms/:fid/users/:uid',
            name: 'forms-id-users-id',
            component: () => import('@/pages/forms/id/users/id')
        },
        {
            path: '/admin/http',
            name: 'admin-http',
            component: () => import('@/pages/admin/http')
        },
        {
            path: '/subscriber',
            name: 'subscriber',
            component: () => import('@/pages/subscriber')
        },
        {
            path: '/books/manage',
            name: 'books-manage',
            component: () => import('@/pages/books/index')
        },
        {
            path: '/books/test',
            name: 'books-manage',
            component: () => import('@/pages/books/index2')
        },
        {
            path: '/books',
            name: 'books',
            component: () => import('@/pages/books/index')
        },
        {
            path: '/cio-table',
            name: 'cio-table',
            component: () => import('@/pages/cioTable/index')
        },
        {
            path: '/sync-terms',
            name: 'sync-terms',
            component: () => import('@/pages/syncTerms/index')
        },
        {
            path: '/events/:id/mailshots',
            name: 'events-id-mailshots',
            component: () => import('@/pages/mailshots/index')
        },
        {
            path: '/events/:id/storage',
            name: 'events-id-storage',
            component: () => import('@/pages/events/id/storage')
        },
        {
            path: '/events/:id/mailshots/:mailId',
            name: 'events-id-mailshots-mailId',
            component: () => import('@/pages/mailshots/id')
        },
        {
            path: '/surveys/:id/mailshots',
            name: 'surveys-id-mailshots',
            component: () => import('@/pages/mailshots/index')
        },
        {
            path: '/surveys/:id/mailshots/:mailId',
            name: 'surveys-id-mailshots-mailId',
            component: () => import('@/pages/mailshots/id')
        },
        {
            path: '/dilbert',
            name: 'dilbert',
            component: () => import('@/pages/dilbert/index')
        },
        {
            path: '/mailchimp',
            name: 'mailchimp',
            component: () => import('@/pages/mailchimp/index')
        },
        {
            path: '/calendar',
            name: 'calendar',
            component: () => import('@/pages/calendar/index')
        },
        {
            path: '/sponsored-words',
            name: 'sponsored-words',
            component: () => import('@/pages/sponsored-words/index')
        },
        {
            path: '/inspire',
            name: 'inspire',
            component: () => import('@/pages/inspire')
        },
        {
            path: '/google-search-import',
            name: 'google-search-import',
            component: () => import('@/pages/google-search/import'),
        },
        {
            path: '*',
            redirect: '/'
        },
        {
            path: '/section-balancing',
            name: 'section-balancing',
            component: () => import('@/pages/section-balancing/section-balancing'),
        }
    ]
})
