export default {
    namespaced: true,
    state: {
        type: localStorage.getItem('publication') || 'itweb'
    },
    mutations: {
        setPublication(state, publication) {
            localStorage.setItem('publication', publication);
            state.type = publication;
        }
    },
}
