import {firebaseAction, firebaseMutations} from 'vuexfire'
import {article} from '../article.js'
export default {
    strict: true,
    state: {
        article: null,
        articles: [],
        revisions: [],
        revision: false,
        pagination: {
            q: '',
            to: null,
            limit: 50,
            modifiedAt: new Date().toISOString().substr(0, 10),
            state: ''
        },
        save: false,
        uuid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    },
    mutations: {
        revision(state, status) {
            state.revision = status
        },
        setPagination(state, pagination) {
            state.pagination = pagination
        },
        reset(state) {
            state.article = article()
        },
        save(state, value) {
            state.save = value
        },
        emptyRevisions(state) {
            state.revisions = []
        },
        setArticleUuid(state, uuid) {
            if (state.article.modifiedBy) {
                state.article.modifiedBy.uuid = uuid
            } else {
                state.article.modifiedBy = {uuid}
            }
        },
        ...firebaseMutations // Pass the module name as an argument
    },
    getters: {
        article: state => state.article,
        articles: state => state.articles,
        revisions: state => state.revisions,
        uuid: state => state.uuid,
        save: state => state.save,
        revision: state => state.revision
    },
    actions: {
        setArticle: firebaseAction(({bindFirebaseRef}, ref) => {
            return bindFirebaseRef('article', ref);
        }),
        setArticles: firebaseAction(({bindFirebaseRef}, ref) => {
            return bindFirebaseRef('articles', ref);
        }),
        setRevisions: firebaseAction(({bindFirebaseRef}, ref) => {
            return bindFirebaseRef('revisions', ref)
        })
    },
}
